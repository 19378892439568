/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.buttonsHolder {
    display: flex;
    justify-content: center;
}

.foldersHolder {
    height: 350px;
    max-width: 100%;
}

.title {
    margin: 25px;
}

.badgeCounter {
    color: red;
    font-size: 15px;
}

.badgeCounterZero {
    color: green;
    font-size: 15px;
}

.visibleElement {
    visibility: visible;
    display: block;
}

.hiddenElement {
    visibility: hidden;
}

.hiddenElement2 {
    display: none;
}

.navigationDropdown {
    background-color: white !important;
    color: black !important;
}